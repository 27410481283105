import * as React from "react"
import { graphql } from "gatsby"
import { Link } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import styled from "styled-components"
import TopSub from "../components/top-sub"
import Container from "../atomic/partials/container"
import { color } from "../components/colors"

const Wrapper = styled.div`
  padding-top: 32px;
`

const Path = styled.div`
  display: flex;
  margin-bottom: 64px;
  font-weight: 400;

  a {
    text-transform: uppercase;
    text-decoration: none;
    transition: color 0.3s ease;
    color: #000;
    margin-right: 6px;

    &:hover {
      color: ${color.primary};
    }
  }
`

const BoxesWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 60px 30px;
  width: 100%;

  @media (min-width: 768px) {
    grid-template-columns: 1fr 1fr;
  }
`

const Box = styled.div`
  width: 100%;
  padding: 40px 15px;
  border-width: 5px 0 2px;
  border-color: ${color.blueLight};
  border-style: solid;

  h2 {
    margin-bottom: 20px;
  }

  h3 {
    margin: 24px 0;
  }

  ol {
    padding-left: 18px;
  }

  ul {
    list-style: none;
  }

  ol,
  ul {
    li {
      font-size: 14px;
      line-height: 17px;
      font-weight: 400;
      margin-bottom: 8px;
    }
  }

  span {
    font-size: 14px;
    line-height: 17px;
    font-weight: 400;
  }
`

const Wladze = ({ data }) => (
  <Layout>
    <Seo />
    <TopSub />
    <Container>
      <Wrapper>
        <Path>
          <Link to="/">SM TARAS PÓŁNOC</Link> / WŁADZE
        </Path>
        <BoxesWrapper>
          <Box>
            <h2>Rada Nadzorcza</h2>
            <ol>
              {data.wpPage.wladze.radaNadzorcza.rdCzOnkowie.map((person, i) => (
                <li>{person.rdNazwa}</li>
              ))}
            </ol>
          </Box>
          <Box>
            <h2>Zarząd</h2>
            <ol>
              {data.wpPage.wladze.zarzad.map((person, i) => (
                <li>{person.zNazwa}</li>
              ))}
            </ol>
          </Box>
          <Box>
            <h2>Administracja</h2>
            <ol>
              {data.wpPage.wladze.administracja.map((person, i) => (
                <li>{person.aNazwa}</li>
              ))}
            </ol>
            <h3>Inspektor Ochrony Danych Osobowych</h3>
            <span> {data.wpPage.wladze.inspDanych}</span>
          </Box>
          <Box>
            <h2 style={{ fontSize: "16px" }}>
              Nr. wew. do poszczególnych działów
            </h2>
            <ul style={{ marginBottom: "32px" }}>
              {data.wpPage.wladze.telefony.map((number, i) => (
                <li>{number.numerTel}</li>
              ))}
            </ul>
            <ul>
              {data.wpPage.wladze.numWew.map((number, i) => (
                <li>{number.numer}</li>
              ))}
            </ul>
          </Box>
        </BoxesWrapper>
      </Wrapper>
    </Container>
  </Layout>
)

export default Wladze

export const pageQuery = graphql`
  query WordpressWladze {
    wpPage(id: { eq: "cG9zdDoy" }) {
      wladze {
        radaNadzorcza {
          rdCzOnkowie {
            rdNazwa
          }
        }
        administracja {
          aNazwa
        }
        telefony {
          numerTel
        }
        zarzad {
          zNazwa
        }
        inspDanych
        numWew {
          numer
        }
      }
    }
  }
`
